const app = app || {};

app.slick = function() {
	const self = this;
	this.init = () => {
		$(document).ready(() => {
			if($('.slick').length > 0) {
				$('.slick').slick({
					dots: true,
					infinite: false,
					slidesToScroll: 1,
					arrows: false
				}).slick('slickGoTo', 4);
			}
		});
	};
	this.init();
};

const jsSlick = new app.slick();