const app = app || {};

(function(a,b,c){if(c in b&&b[c]){var d,e=a.location,f=/^(a|html)$/i;a.addEventListener("click",function(a){d=a.target;while(!f.test(d.nodeName))d=d.parentNode;"href"in d&&(d.href.indexOf("http")||~d.href.indexOf(e.host))&&(a.preventDefault(),e.href=d.href)},!1)}})(document,window.navigator,"standalone")

app.toggle = function() {
	const self = this;
	this.init = () => {
		this.bindControls();
	};
	this.bindControls = () => {
		$(document).on('touchstart', '.toggle', function() {
			$(this).toggleClass('toggle--checked');
		}).on('touchstart', '.checkbox', function() {
			$(this).toggleClass('checkbox--checked');
		});
	};
	this.init();
};

const jsToggle = new app.toggle();