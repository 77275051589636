const app = app || {};

app.accordion = function($container) {
	const self = this;
	this.init = () => {
		this.hideAllSections();
		this.bindControls();
	};
	this.bindControls = () => {
		$container.find('.accordion__header').on('click', function() {
			const $section = $(this).parent('.accordion__section');
			console.log($section);
			self.toggleSection($section);
		});
	};
	this.hideAllSections = () => {
		$container.find('.accordion__section').each((i, section) => {
			this.hideSection($(section));
		});
	};
	this.showAllSections = () => {
		$container.find('.accordion__section').each((i, section) => {
			this.showSection($(section));
		});
	};
	this.toggleSection = ($section) => {
		if($section.hasClass('accordion__section--collapsed')) {
			console.log('is collapsed')
			this.showSection($section);
		}
		else {
			console.log('is NOT collapsed')
			this.hideSection($section);
		}
	};
	this.hideSection = ($section) => {
		$section.addClass('accordion__section--collapsed');
	};
	this.showSection = ($section) => {
		$section.removeClass('accordion__section--collapsed');
	};
	this.init();
};

$('.accordion').each(function() {
	let jsAccordion = new app.accordion($(this));
});