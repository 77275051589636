const app = app || {};

app.listview = function() {
	const self = this;
	this.init = () => {
		this.bindControls();
	};
	this.bindControls = () => {
		// $(document).on('click', '[data-edit-listview]', () => {
		// 	$('.listview').toggleClass('listview--editing');
		// 	$('.footer--conditional').toggleClass('hidden');
		// });
		let movement = 0;
		$('.listview--swipable .listview__item').hammer().on('tap panmove', function(e) {
			if(e.gesture.additionalEvent == 'panleft') {
				let distance = e.gesture.distance;
				if(distance > 80) { 
					distance = 80; 
				} else if(distance < 0) {
					distance = 0;
				}
				movement = distance;
				$(this).css({'transform': `translateX(${0 - movement}px)`});
			}
			else {
				$('.listview--swipable .listview__item').css({'transform': ''});
			}
		}).on('panend', function(e) {
			if(movement > 40) {
				movement = 80;
			}
			else {
				movement = 0;
			}
			$(this).css({'transform': `translateX(${0 - movement}px)`});
		});
	};
	this.init();
};

