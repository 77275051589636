const app = app || {};

app.toast = function() {
	const self = this;
	this.init = () => {
		this.bindControls();
	};
	this.bindControls = () => {
		$(document).on('click touchstart', '[data-toast]', function(e) {
			e.preventDefault();
			const toastMessage = $(this).attr('data-toast');
			const toastRedirect = $(this).attr('href');
			self.makeToast(toastMessage, toastRedirect);
		});
	};
	this.makeToast = (msg, redirect = false) => {
		const $toast = $(`<div class="toast"><div class="toast__inner">${msg}</div></div>`);
		$('body').append($toast);
		setTimeout(() => {
			$('.toast').addClass('toast--animate');
		}, 10);
		if(redirect && redirect != '#') {
			setTimeout(() => {
				window.location = redirect;
			}, 4000)
		}
	};
	this.init();
};

const jsToast = new app.toast();