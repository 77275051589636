const app = app || {};

app.fab = function() {
	const self = this;
	this.init = () => {
		console.log('clicky doodad')
		$(document).on('click', '.fab__button', function() {
			$(this).parent('.fab').toggleClass('fab--open');
			$('body').toggleClass('show-shade');
		});
	};
	this.init();
};

const jsFAB = new app.fab();