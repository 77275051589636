const app = app || {};

app.icons = function() {
	const self = this;
	const mode = $('html').hasClass('android') ? 'android' : 'ios';
	this.init = () => {
		$(window).on('load', () => {
			this.loadSpritesheet();
		});
	};
	this.loadSpritesheet = () => {
		$.ajax({
			url: `icons-${mode}.svg`,
			method: 'GET'
		}).done((data) => {
			this.replaceIcons();
		});
	};
	this.replaceIcons = () => {
		$('icon').each((i, icon) => {
			const name = $(icon).attr('name');
			console.log('insert icon', mode, name);
			const $use = $('<use/>', {'xlink:href': `icons-${mode}.svg#${name}`});
			const $replace = $('<svg/>', {'class': $(icon).attr('class')}).append($use);
			$(icon).replaceWith($replace);
		});
	};
	this.init();
};

// const jsIcons = new app.icons();